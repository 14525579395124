<template>
  <div>
    <div v-for="item in list" :key="item.title">
      <el-submenu v-if="item.children && item.children.length" :index="item.id?`/documents/${item.id}`:item.title">
        <template slot="title">
          <router-link v-if="item.id" tag="div" :to="''+item.id" class="ellipsis">
            {{item.title}}
          </router-link>
          <div v-else class="ellipsis">
            {{item.title}}
          </div>
        </template> 
        <el-menu-item-group>
          <menu-item :list="item.children"></menu-item>
        </el-menu-item-group>
      </el-submenu>
      <!-- :default-active="$route.path" -->
      <el-menu-item v-else :index="'/documents/'+item.id" >
        <div slot="title" class="ellipsis">{{item.title}}</div>
      </el-menu-item>
    </div>
  </div>
</template>

<script>
  import menuItem from "@/components/documents/menuItem";
  export default {
    name:'menu-item',
    components: {
      menuItem
    },
    props:['list'],
    created(){
    },
    data() {
      return {
        
      };
    },
    methods: {
    }
  };
</script>

<style scoped lang="scss">
  .nav-box{
    padding: 10px;
    & > div{
      padding-left: 10px;
      line-height: 30px;
    }
    .name{
      height: 30px;
      vertical-align: middle;
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name:hover{
      color: #1989fa;
      cursor: pointer;
    }
  }
</style>
