<template>
  <div class="documentsBox">
    <div class="menu-navBox">
      <el-menu
        class="menu-nav"
        background-color="#282828"
        text-color="#81818C"
        active-text-color="#fff"
        :default-active="$route.path"
        unique-opened
        router>
        <menu-item :list="documentData"></menu-item>
      </el-menu>
      
      <div class="menu-content" >
        <div class="padding20">
          <!-- <el-breadcrumb class="breadcrumb" separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>标准文档</el-breadcrumb-item>
            <el-breadcrumb-item>{{activeMenuName}}</el-breadcrumb-item>
          </el-breadcrumb> -->
          <div class="documentsContent">
            <!-- <router-view class="page-box"></router-view> -->
            <document class="page-box" v-if="isRouterAlive"></document>
          </div>
          <ICP bgcClor="#fafafa" color="#bdc2c7"></ICP>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ICP from "@/components/ICP";
import document from "@/components/documents/document";
import menuItem from "@/components/documents/menuItem";
import { singleDocumentsSever } from "@/common/api-config";
export default {
  components: {
    ICP,document,menuItem
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  created() {
    this.queryMenuList();
  },
  mounted(){
  },
  watch: {
    '$route.params.id' (to, from) {
      // this.queryDocumentDetail();
    }
  },
  data() {
    return {
      isRouterAlive: true,
      id:'',
      documentData: [],
      activeRouter: '',
      activeMenuName:'',
    };
  },  
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    queryMenuList() {
      singleDocumentsSever.queryMenuList().then(res => {
        if (res.code == 0) {
          this.documentData = res.data;
          if(!this.$route.params.id && this.documentData.length ){
            this.recursionId(this.documentData)
            setTimeout(()=>{
              this.$router.push({
                path:'/documents/'+this.id,
              })
            },30)
          }
        }
      });
    },
    recursionId(list=[]) {
      for (var i = 0; i < list.length; i++) {
        if(list[i].id ){
          if(this.id==''){
            this.id=list[i].id
          }
          break;
        }else{
            this.recursionId(list[i].children)
        }
      };
    },
  }

};
</script>

<style scoped lang="scss">
/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-scrollbar__view {
  height: 100%;
}
.documentsBox {
  margin-top: 80px;
  height: calc(100% - 80px); //减头高度
  .menu-navBox {
    display: flex;
    height: 100%;
    .menu-nav {
      width: 250px;
      padding: 20px 0;
      min-height: calc(100% - 80px); //减头高度
      .menu-navItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 40px!important;
        height: 70px;
        line-height: 70px;
        font-size: 18px;
        .el-icon-arrow-right{
          margin-top: -2px;
        }
      }
      .is-active {
        color: #fff !important;
      }
    }
    .menu-content {
      width: 100%;
      flex: 1;
      background: #fafafa;
      .padding20{
        height: 100%;
        padding: 0px 20px ;
        .documentsContent{
          padding: 20px 10px;
          height: calc(100% - 60px); //减头高度
          .page-box{
            height: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
    .documentsBox {
      .menu-nav{
        display: none;
      }
      .documentsContent{
        padding: 20px!important;
         /deep/ img{
          width: 100%;
         }
      }
    }
}
</style>

